import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
    <Layout>
        <SEO title="Web" />
        <p>Vítejte na stránce, která neobsahuje žádné praktické informace.</p>
        <ul>
            <li>
                <a href="https://twitter.com/marakroman" target="_blank" rel="noopener noreferrer">Twitter</a></li>
            <li><a href="https://www.linkedin.com/in/roman-mařák-79906294" target="_blank" rel="noopener noreferrer">Linkedin</a></li>
            <li><a href="mailto:roman@marax.cz">roman@marax.cz</a></li>
        </ul>
    </Layout>
);

export default IndexPage
